body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #eee;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
}

.content {
    padding-left: 20px;
    padding-right: 20px;
}

.bible {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
    color: #eee;
}

.bible-site {
    margin-top: 5px;
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
    font-weight: 200;
    color: #eee;
}


.info {
    margin: 20px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    color: #eee;
    cursor: pointer;
    text-align: center;
}

a, a:link, a:visited a:hover a:active {
    text-decoration: none;
    color: #eee !important;
}

.play {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

.play img {
    height: 100px;
}

@media (min-width: 961px) {
    .wrapper {
        padding: 10px;
    }

    .content {
        padding: 0px;
    }

    body {
        font-size: 1.5rem;
    }
}

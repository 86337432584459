.hands {
    margin: 20px auto;
    width: 90%;
    display: block;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #eee;
    text-align: center;
    margin-top: 10px;
}

.prayer-image {
    height: auto;
    width: 100%;
}


.prayer {
    margin-top: 30px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #eee;
}
